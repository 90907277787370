:root {
	--main-background: #273239;
	--terminal-border-color: #404A4F;
	--terminal-path-color: #D370D1;
	--terminal-history-path-color: #A5AAAC;
	--terminal-input-color: #5AB604;
	--terminal-command-error-color: #F56E5D;
	--terminal-command-error-background: #3B3538;
	--terminal-scrollbar-background: #525B60;
	--terminal-directory-color: #5AB604;
	--terminal-directory-options-color: #D4D6D7;
	--terminal-history-popup-background: #475055;
	--terminal-history-popup-item-background: #00C2FF;
	--terminal-history-popup-item-color: #176D8A;
}

* {
	box-sizing: border-box;
}

p {
	margin: 0;
}

.unselectable {
	-moz-user-select: none;
	-khtml-user-select: none;
	-webkit-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

.scrollbar {
	&::-webkit-scrollbar {
		width: 6px;
		height: 6px;
	}

	&::-webkit-scrollbar-thumb {
		background: var(--terminal-scrollbar-background);
		border-radius: 5px;
	}

	&::-webkit-scrollbar-track {
		background: none;
	}
}


body, html, #root {
	width: 100%;
	height: 100%;
}

body {
	padding: 0;
	margin: 0;
	background: var(--main-background);
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
	'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
	sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.portfolio-wrap {
	position: relative;
	width: 100%;
	height: 100%;

	.help {
		position: fixed;
		cursor: pointer;
		width: 50px;
		height: 50px;
		bottom: 20px;
		right: 20px;
		background: #21B241;
		color: #fff;
		font-size: 20px;
		font-weight: bold;
		border-radius: 50px;
		padding: 0;
		border: none;
	}
}

.commands-wrap {
	@extend .scrollbar;

	position: absolute;
	bottom: 120px;
	left: 0;
	width: 100%;
	max-height: calc(100% - 120px);
	overflow-y: auto;

	ul {
		padding: 0;
		margin: 0;
		list-style: none;

		li {
			padding: 20px;
			border-top: 1px solid var(--terminal-border-color);

			p.path {
				@extend .unselectable;

				color: var(--terminal-history-path-color);
				font-size: 14px;
				font-weight: bold;
			}

			p.error-text {
				margin-top: 12px;
				color: var(--terminal-history-path-color);
				font-size: 14px;
			}

			p.command {
				margin-top: 12px;
				color: var(--terminal-input-color);
				font-size: 14px;
			}

			&.error {
				position: relative;
				background: var(--terminal-command-error-background);


				&:before {
					position: absolute;
					top: 0;
					left: 0;
					width: 4px;
					height: 100%;
					background: var(--terminal-command-error-color)
				}

				p.command {
					color: var(--terminal-command-error-color);
					content: '';
				}
			}
		}
	}
}

.terminal-commands-list {
	margin-top: 12px;
	margin-left: -14px;

	.block {
		display: flex;
		align-items: center;
		margin-left: 14px;
		column-gap: 10px;

		&:not(:first-child) {
			margin-top: 2px;
		}

		i {
			color: var(--terminal-directory-options-color);
		}
	}

	.inline {
		display: inline-block;
		margin-left: 14px;
		color: var(--terminal-directory-color);
	}

	span {
		color: var(--terminal-directory-color);

		&.file {
			color: var(--terminal-directory-options-color);
		}
	}
}

.terminal-input-wrap {
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
	height: 120px;
	padding: 20px;
	border-top: 1px solid var(--terminal-border-color);

	p {
		@extend .unselectable;

		color: var(--terminal-path-color);
		font-size: 14px;
		font-weight: bold;
	}


	.terminal-input {
		min-height: 50px;
		margin-top: 12px;
		outline: 0;
		color: var(--terminal-input-color);
		font-size: 14px;

		&.fish-text {
			@extend .unselectable;

			opacity: 0.4;
			position: absolute;
			top: 37px;
			left: 20px;
		}
	}
}

.terminal-history-popup {
	position: absolute;
	width: 100%;
	height: 100%;
	bottom: 0;
	left: 0;
}

.terminal-history-wrap {
	@extend .unselectable;
	@extend .scrollbar;

	position: absolute;
	bottom: 120px;
	left: 14px;
	width: 80%;
	max-height: 200px;
	background: var(--terminal-history-popup-background);
	border: 1px solid var(--terminal-border-color);
	border-radius: 10px 10px 0 0;
	outline: none;
	overflow-y: auto;

	option {
		padding: 10px;
		color: var(--terminal-directory-options-color);
		font-size: 14px;

		&:focus, &:hover, &:checked {
			background: var(--terminal-history-popup-item-background) !important;
			cursor: pointer;
			color: var(--terminal-history-popup-item-color);
		}
	}
}

.modal {
	position: fixed;
	display: flex;
	z-index: 100;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 100%;
	background: rgba(39, 50, 57, 1);

	.close-button {
		position: absolute;
		top: 10px;
		right: 10px;
		width: 35px;
		height: 35px;
		padding: 0;
		background: none;
		border: none;
		outline: none;
		cursor: pointer;

		&:after, &:before {
			position: absolute;
			top: 0;
			left: 50%;
			display: block;
			width: 4px;
			height: 35px;
			background: rgba(255,255,255, 0.5);
			border-radius: 2px;
			transform-origin: center;
			content: "";
		}

		&:after {
			transform: rotate(45deg);
		}

		&:before {
			transform: rotate(-45deg);
		}
	}

	.modal-content {
		> div {
			padding: 20px !important;
		}
	}
}

.contacts-wrap {
	display: flex;
	justify-content: center;
	padding: 40px 0 0;
	flex-wrap: wrap;

	a {
		display: block;
		width: 72px;
		margin-right: 10px;
		margin-bottom: 20px;
		margin-left: 10px;

		.imageWrap {
			display: block;
			width: 72px;
			height: 72px;
			background: #7B7B7B;
			border-radius: 15px;

			img {
				visibility: hidden;
			}

			&.loaded {
				background: none;

				img {
					visibility: visible;
				}
			}
		}

		img {
			width: 72px;
			height: 72px;
			border-radius: 15px;
			filter: var(--team-filter);
			transition: filter 0.3s ease;

			@media (hover: hover) {
				&:hover {
					filter: var(--team-filter-hover);
				}
			}
		}
	}
}

.skills-wrap {
	@extend .unselectable;
	padding: 40px 0 0;

	ul {
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		padding: 0;

		li {
			display: flex;
			flex-direction: column;
			align-items: center;
			margin-right: 15px;
			margin-bottom: 20px;
			margin-left: 15px;
			padding: 0;
			border: none;

			.image-wrap {
				width: 72px;
				height: 72px;
			}

			img {
				display: block;
				max-width: 72px;
				max-height: 72px;
			}

			> span {
				display: block;
				margin-top: 10px;
				color: white;
				text-align: center;
				font-size: 14px;
				opacity: 0.5;
				transition: all 0.3s ease;
			}
		}
	}
}

.about-wrap {
	padding: 40px 0 0;

	p {
		margin: 0 auto;
		width: 50%;
		color: var(--terminal-history-path-color);
	}
}

.experience-wrap {
	padding: 40px 0 0;
	display: flex;
	justify-content: center;

	> ul {
		padding: 0;
		display: flex;
		flex-wrap: wrap;
		width: 100%;
		overflow-y: auto;
		list-style: none;

		> li {
			width: 33%;
			margin-bottom: 20px;
			padding: 0 0 20px;
			border: none;
			border-bottom: 1px solid rgba(255, 255, 255, 0.1);

			p {
				margin-top: 5px;
				color: white;
				font-size: 16px;
				line-height: 18px;

				&:nth-of-type(2) {
					font-size: 14px;
				}
			}

			ul {
				padding-left: 15px;
				list-style: disc;

				li {
					border:none;
					margin-top: 5px;
					opacity: 0.6;
					color: white;
					font-size: 14px;
					line-height: 18px;
					padding: 0;
				}
			}
		}
	}
}

.team-wrap {
	padding: 40px 0 0;
	display: flex;
	justify-content: center;

	> ul {
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		width: 100%;
		overflow-y: auto;
		list-style: none;
		padding: 0;

		> li {
			width: 150px;
			margin: 0 10px 20px;
			border: none;
			padding: 0;

			a {
				text-decoration: none;
			}

			img {
				display: block;
				width: 150px;
				height: 150px;
				border-radius: 150px;
				transition: all 0.3s ease;
				filter: var(--team-filter);
			}

			h1 {
				margin-top: 5px;
				opacity: 0.6;
				color: white;
				text-align: center;
				font-size: 16px;
				line-height: 18px;
			}

			p {
				margin-top: 5px;
				opacity: 0.3;
				color: white;
				text-align: center;
				font-size: 14px;
				line-height: 16px;
			}
		}
	}
}

.help-wrap {
	padding: 20px;

	h1 {
		text-align: center;
		color: white;
	}

	ul {
		margin-top: 40px;

		li {
			margin-top: 5px;
			color: white;
		}
	}
}

@media screen and (max-width: 600px) and (orientation: portrait) {
	.contacts-wrap {
		width: 100%;

		a {
			width: 50px;

			img {
				width: 50px;
				height: 50px;
			}
		}
	}

	.about-wrap {
		p {
			width: 100%;
		}
	}


	.skills-wrap {
		ul {
			li {
				.image-wrap {
					width: 50px;
					height: 50px;
				}

				img {
					max-width: 50px;
					max-height: 50px;
				}
			}
		}
	}

	.experience-wrap {
		> ul {
			> li {
				width: 100%;
			}
		}
	}

	.team-wrap {
		> ul {
			justify-content: center;
			padding-right: 10px;

			> li {
				width: 130px;

				.imageWrap {
					width: 130px;
					height: 130px;
				}

				img {
					width: 130px;
					height: 130px;
				}
			}
		}
	}

	.modal-content {
		.experience-wrap, .team-wrap {
			> ul {
				max-height: 80vh;
			}
		}
	}
}
